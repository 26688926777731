<template>
<v-main app>
  <v-container>
    <v-row justify="center">
      <v-col md="6" sm="12">
      <v-alert v-if="submitted" class="mt-16"
        border="top"
        colored-border
        type="info"
        elevation="2"
      >
        <p>If {{loginEmail}} is a valid user account, we will send you an email with a link to reset your password. Please note that the link will expire 1 hour after the email is sent.</p>
      </v-alert>
      <v-card v-if="!submitted" class="mt-16">
        <v-card-title class="mb-4 text-xs-center justify-center primary title white--text font-weight-bold">
          Request Password Reset
        </v-card-title>
        <v-card-text>
          <v-form ref="loginForm" v-model="valid" >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="loginEmail"
                  :rules="loginEmailRules"
                  label="E-mail"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                <v-btn
                  x-large
                  block
                  :disabled="!valid"
                  color="success"
                  @click="submitReset"
                >
                  Send
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      </v-col>
    </v-row>
  </v-container>
</v-main>
</template>

<script>
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/public/login/requestPasswordReset`
export default {
  name: 'Doricmor',
  metaInfo: {
    title: "Reset your password"
  },
  data: () => ({
    submitted: false,
    valid: false,
    loginEmail: null,
    loginEmailRules: [
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'

    ]
  }),
  computed: {},
  methods: {
    submitReset () {
      if (this.$refs.loginForm.validate()) {
        const email = this.loginEmail
        this.$axios.post(api, { email: email })
          .then(data => {
            this.submitted = true
          })
          .catch(data => {
            console.log('error', data)
          })
      }
    }
  }
}
</script>
